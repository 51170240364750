import {
  RadarRing,
  RadarQuadrant,
  RadarEntry,
  TechRadarLoaderResponse,
  TechRadarApi
} from '@backstage-community/plugin-tech-radar';

import techRadarData from './techRadar.json';

export const TechRadarLoaderData: TechRadarLoaderResponse = {
  entries: new Array<RadarEntry>(),
  quadrants: new Array<RadarQuadrant>(),
  rings: new Array<RadarRing>(),
};

export class TechRadarClient implements TechRadarApi {
  async load() {

    TechRadarLoaderData.rings.push({ id: 'adopt', name: 'ADOPT', color: '#044122' });
    TechRadarLoaderData.rings.push({ id: 'trial', name: 'TRIAL', color: '#0e474d' });
    TechRadarLoaderData.rings.push({ id: 'assess', name: 'ASSESS', color: '#c99302' });
    TechRadarLoaderData.rings.push({ id: 'hold', name: 'HOLD', color: '#751d14' });

    TechRadarLoaderData.quadrants.push({ id: 'techniques', name: 'Techniques & process' });
    TechRadarLoaderData.quadrants.push({ id: 'platforms', name: 'Platforms' });
    TechRadarLoaderData.quadrants.push({ id: 'languages', name: 'Languages & Frameworks' });
    TechRadarLoaderData.quadrants.push({ id: 'tools', name: 'Tools' });

    for (var i in techRadarData) {
      TechRadarLoaderData.entries.push({
        timeline: [
          {
            moved: techRadarData[i]["timeline"][0]["moved"],
            ringId: techRadarData[i]["timeline"][0]["ringId"],
            date: new Date(techRadarData[i]["timeline"][0]["dateString"]),
            description: techRadarData[i]["timeline"][0]["description"]
          }
        ],
        url: techRadarData[i]["url"],
        key: techRadarData[i]["key"],
        id: techRadarData[i]["id"],
        title: techRadarData[i]["title"],
        quadrant: techRadarData[i]["quadrant"],
        description: techRadarData[i]["description"]
      });
    }

    return TechRadarLoaderData;
  }
}
